
























import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import NotifyMixin from '@/mixins/NotifyMixin'
import { ManagerCoursesTreeSelectGetParams, NameValueChildrenCustomResource, NameValueChildrenResource } from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'
import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import ManagerGroupsModule from '@/store/modules/manager/groups'

@Component({
  inheritAttrs: false,
})
export default class CourseSearch extends Mixins(DetectSafariMixin, NotifyMixin) {
  @Prop({ required: true })
  private value!: number

  @Prop({ default: null })
  private subjectId!: number

  @Prop( { default: false })
  private returnObject!: boolean

  @Prop({ default: false })
  private invalid!: boolean

  @Prop({ default: '' })
  private error!: string

  @Prop({ default: true })
  private isFilter!: boolean

  @Prop({ default: false })
  private isTreeId!: boolean

  @Prop({ default: false })
  private unallocatedCourses!: boolean

  private innerValue = 0
  private search = ''
  private isLoading = false
  private courses: Array<NameValueChildrenCustomResource | NameValueChildrenResource> = []

  private mounted () {
    if (this.value) {
      this.innerValue = this.value
    }
    this.handleSearch(this.search)
  }

  private handleSelect (value: number) {
    if (!this.returnObject) {
      this.$emit('update:value', value)
      this.$emit('input', value)
    } else {
      let course = this.courses.find(item => item.value === value)
      this.$emit('update:value', value)
      this.$emit('input', course)
    }
    this.search = ''
    this.innerValue = 0
  }

  @Bind
  @Debounce(500)
  private handleSearch(query: string) {
    if (this.isLoading) return

    this.isLoading = true

    if (this.unallocatedCourses) {
      this.unallocatedCoursesTree()
      return
    }

    this.searchCoursesTree(query)
  }

  private unallocatedCoursesTree() {
    ManagerGroupsModule.fetchManagerCoursesUnallocated()
      .then((response: NameValueChildrenCustomResource[]) => {
        this.courses = [...response]
      })
      .catch(this.notifyError)
      .finally(() => (this.isLoading = false))
  }

  private searchCoursesTree(query: string) {
    DictionaryModule.searchCoursesTree({
      groupReturnId: this.isTreeId ? this.isTreeId : undefined,
      query,
      subjectId: this.subjectId || undefined,
    } as ManagerCoursesTreeSelectGetParams)
      .then((response: NameValueChildrenResource[]) => {
        this.courses = [...response]
        this.$emit('updateList', this.courses)
      })
      .catch(this.notifyError)
      .finally(() => (this.isLoading = false))
  }

  @Watch('search')
  private watchSearch (value: string | null, oldValue: string | null) {
    if (value !== oldValue && value && oldValue !== '' && !this.unallocatedCourses) {
      this.handleSearch(value)
    }
  }

  @Watch('subjectId')
  private watchSubjectId () {
    this.$emit('update:value', undefined)
    this.$emit('input', undefined)
    // this.search = ''
    this.courses = []
    this.innerValue = 0
  }
}
